import { dayjs } from '@/plugins/dayjs'
import { type GetJukuConfigByJukuIdResponse } from '@/types/codegen/schema'
import { DATE_FORMATS } from '@/modules/util'

export const state = (): GetJukuConfigByJukuIdResponse => ({
  __typename: 'JukuConfig',
  id: -1,
  jukuId: -1,
  currentFiscalYear: parseInt(dayjs().format(DATE_FORMATS.year)),
  promotionPlanDate: '',
  lineLinkFlag: false,
  parentContactFlag: false
})

export const mutations = {
  set(s: any, jukuConfig: any) {
    Object.assign(s, { ...jukuConfig })
  }
}

export const actions = {
  set({ commit }: any, data: any) {
    commit('set', data)
  },
  reset({ commit }: any) {
    commit('set', {
      __typename: 'JukuConfig',
      id: -1,
      jukuId: -1,
      currentFiscalYear: parseInt(dayjs().format(DATE_FORMATS.year)),
      promotionPlanDate: '',
      lineLinkFlag: 0,
      parentContactFlag: 0
    })
  }
}
