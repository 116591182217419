
import {
  computed,
  defineComponent,
  provide,
  ref,
  useContext,
  useFetch,
  useRoute,
  useRouter
} from '@nuxtjs/composition-api'
import type { InjectionKey, Ref } from 'vue'
import { useStore } from '@/useCases/useStore'
import { type ModulesState } from '@/student/store/userStateType'
import { useSnackbar, SnackbarKey } from '@/useCases/snackBar'
import {
  studentReadNotificationRepo,
  type StudentReadNotificationRepoResponse
} from '@/repos/notifications/studentReadNotification'

export const StudentReadNotificationsInjectionKey: InjectionKey<
  Ref<StudentReadNotificationRepoResponse.fetchByStudentId>
> = Symbol('StudentReadNotificationsInjectionKey')

export interface SideNavOptions {
  icon: string
  title: string
  to: string
}

const SIDE_MENU_OPTIONS_MOBILE = [
  {
    icon: 'mdi-clock-outline',
    title: 'タイムライン',
    to: '/timeline'
  },
  {
    icon: 'mdi-account-circle-outline',
    title: 'アカウント',
    to: '/account'
  }
] as const

const SIDE_MENU_OPTIONS_PC = [
  {
    icon: 'mdi-clock-outline',
    title: 'タイムライン',
    to: '/timeline'
  },
  {
    icon: 'mdi-bell-outline',
    title: 'おしらせ',
    to: '/notifications'
  },
  {
    icon: 'mdi-calendar',
    title: '指導',
    to: '/calendar'
  },
  {
    icon: 'mdi-file-chart-outline',
    title: '成績',
    to: '/grades'
  },
  {
    icon: 'mdi-book-open-blank-variant',
    title: '教材',
    to: '/materials'
  },
  {
    icon: 'mdi-account-circle-outline',
    title: 'アカウント',
    to: '/account'
  }
] as const
export default defineComponent({
  setup() {
    const { $vuetify, i18n } = useContext()
    const route = useRoute()
    const router = useRouter()

    const store = useStore<ModulesState>()

    const snackbarCtx = useSnackbar()

    const user = computed(() => store.state.user)

    const idShowedBrowserBackButton = computed(
      () =>
        (route.name?.includes('id') ||
          route.name?.includes('Id') ||
          route.name?.includes('account-edit') ||
          route.name?.includes('room-access-qr')) &&
        $vuetify.breakpoint.mobile
    )
    const toPreviousPage = () => router.back()

    const layoutOptions = computed(() => ($vuetify.breakpoint.mobile ? SIDE_MENU_OPTIONS_MOBILE : SIDE_MENU_OPTIONS_PC))
    const isOpenedSideMenu = ref(false)

    const title = computed(() => {
      const routeName = i18n.messages.ja.routeName

      return Object.keys(routeName).includes(route.name || '') ? i18n.tc(`routeName.${route.name}`) : 'reco_for_parent'
    })

    /**
     * フッターの未読バッジで必要なのでdefault.vueからprovideする
     */
    const studentReadNotifications = ref<StudentReadNotificationRepoResponse.fetchByStudentId>([])
    provide(StudentReadNotificationsInjectionKey, studentReadNotifications)
    useFetch(async () => {
      studentReadNotifications.value = await studentReadNotificationRepo.fetchByStudentId(user.value.id)
    })

    provide(SnackbarKey, snackbarCtx)

    return {
      layoutOptions,
      isOpenedSideMenu,

      title,
      ...snackbarCtx,
      idShowedBrowserBackButton,
      toPreviousPage
    }
  }
})
