import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d48722a = () => interopDefault(import('../student/pages/account.vue' /* webpackChunkName: "student/pages/account" */))
const _03d79da4 = () => interopDefault(import('../student/pages/account/index.vue' /* webpackChunkName: "student/pages/account/index" */))
const _26caede8 = () => interopDefault(import('../student/pages/account/room-access-qr.vue' /* webpackChunkName: "student/pages/account/room-access-qr" */))
const _4d43a7e7 = () => interopDefault(import('../student/pages/account/edit/change-password.vue' /* webpackChunkName: "student/pages/account/edit/change-password" */))
const _42aaea20 = () => interopDefault(import('../student/pages/calendar.vue' /* webpackChunkName: "student/pages/calendar" */))
const _7ebf1d73 = () => interopDefault(import('../student/pages/calendar/index.vue' /* webpackChunkName: "student/pages/calendar/index" */))
const _9b683eca = () => interopDefault(import('../student/pages/calendar/_id.vue' /* webpackChunkName: "student/pages/calendar/_id" */))
const _65468aa4 = () => interopDefault(import('../student/pages/grades.vue' /* webpackChunkName: "student/pages/grades" */))
const _b09bf29e = () => interopDefault(import('../student/pages/grades/index.vue' /* webpackChunkName: "student/pages/grades/index" */))
const _3ee0a5a5 = () => interopDefault(import('../student/pages/grades/original-exam/_id.vue' /* webpackChunkName: "student/pages/grades/original-exam/_id" */))
const _5f449aa2 = () => interopDefault(import('../student/pages/grades/school-exam/_id.vue' /* webpackChunkName: "student/pages/grades/school-exam/_id" */))
const _d4357a26 = () => interopDefault(import('../student/pages/materials/index.vue' /* webpackChunkName: "student/pages/materials/index" */))
const _951d30b4 = () => interopDefault(import('../student/pages/notifications.vue' /* webpackChunkName: "student/pages/notifications" */))
const _1f9f19a9 = () => interopDefault(import('../student/pages/notifications/index.vue' /* webpackChunkName: "student/pages/notifications/index" */))
const _476d1b5e = () => interopDefault(import('../student/pages/notifications/_id.vue' /* webpackChunkName: "student/pages/notifications/_id" */))
const _2abc1bf3 = () => interopDefault(import('../student/pages/sign-in.vue' /* webpackChunkName: "student/pages/sign-in" */))
const _a6e86294 = () => interopDefault(import('../student/pages/timeline/index.vue' /* webpackChunkName: "student/pages/timeline/index" */))
const _6ac77c90 = () => interopDefault(import('../student/pages/index.vue' /* webpackChunkName: "student/pages/index" */))
const _d45dc6d6 = () => interopDefault(import('../student/pages/materials/_id.vue' /* webpackChunkName: "student/pages/materials/_id" */))
const _1547bd36 = () => interopDefault(import('../student/pages/timeline/_komaId.vue' /* webpackChunkName: "student/pages/timeline/_komaId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _0d48722a,
    children: [{
      path: "",
      component: _03d79da4,
      name: "account"
    }, {
      path: "room-access-qr",
      component: _26caede8,
      name: "account-room-access-qr"
    }, {
      path: "edit/change-password",
      component: _4d43a7e7,
      name: "account-edit-change-password"
    }]
  }, {
    path: "/calendar",
    component: _42aaea20,
    children: [{
      path: "",
      component: _7ebf1d73,
      name: "calendar"
    }, {
      path: ":id",
      component: _9b683eca,
      name: "calendar-id"
    }]
  }, {
    path: "/grades",
    component: _65468aa4,
    children: [{
      path: "",
      component: _b09bf29e,
      name: "grades"
    }, {
      path: "original-exam/:id?",
      component: _3ee0a5a5,
      name: "grades-original-exam-id"
    }, {
      path: "school-exam/:id?",
      component: _5f449aa2,
      name: "grades-school-exam-id"
    }]
  }, {
    path: "/materials",
    component: _d4357a26,
    name: "materials"
  }, {
    path: "/notifications",
    component: _951d30b4,
    children: [{
      path: "",
      component: _1f9f19a9,
      name: "notifications"
    }, {
      path: ":id",
      component: _476d1b5e,
      name: "notifications-id"
    }]
  }, {
    path: "/sign-in",
    component: _2abc1bf3,
    name: "sign-in"
  }, {
    path: "/timeline",
    component: _a6e86294,
    name: "timeline"
  }, {
    path: "/",
    component: _6ac77c90,
    name: "index"
  }, {
    path: "/materials/:id",
    component: _d45dc6d6,
    name: "materials-id"
  }, {
    path: "/timeline/:komaId",
    component: _1547bd36,
    name: "timeline-komaId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
