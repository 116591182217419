import { type Middleware } from '@nuxt/types'
import { Auth } from '@aws-amplify/auth'
import { studentRepo } from '@/repos/student'
import { jukuConfigRepo } from '@/repos/jukuConfig'
import { type ErrorResponse } from '@apollo/client/link/error'
import { ApiErrorType } from '@/modules/error'

const EXCLUDED_NAMES = ['sign-in', 'sign-up']

const isMaintenanceMode = (err: ErrorResponse) =>
  err.graphQLErrors?.some(error => error.message === ApiErrorType.MaintenanceMode)

export default <Middleware>async function ({ route, store, redirect, $logger, error }) {
  if (EXCLUDED_NAMES.includes(route.name as string)) return
  if (route.name === 'line-link-sign-in') return

  try {
    const { username } = await Auth.currentAuthenticatedUser()

    const user = await studentRepo.fetchSignedIn(username)
    store.commit('user/set', user)
    // jukuConfigをセット
    const jukuConfig = await jukuConfigRepo.fetch(user.jukuId)
    store.commit('jukuConfig/set', jukuConfig)

    // sentryにusernameを送る
    $logger.identify(user.username, {
      id: user.id,
      username: user.username,
      jukuId: user.jukuId
    })
  } catch (err: any) {
    console.error(err)
    if (isMaintenanceMode(err)) {
      error({ statusCode: 503, message: ApiErrorType.MaintenanceMode })
      return
    }
    redirect('/sign-in')
  }
}
